// Ram Ram
import Logo from "./logo.png";
import { useState } from "react";

import Img1 from "./Img/UI-UXImg/Img1.jpg";
import Img2 from "./Img/UI-UXImg/Img2.jpg";
import Img3 from "./Img/UI-UXImg/Img3.jpg";
import Img4 from "./Img/UI-UXImg/Img4.jpg";
import Img5 from "./Img/UI-UXImg/Img5.jpg";
import Img6 from "./Img/UI-UXImg/Img6.jpg";
import Img7 from "./Img/UI-UXImg/Img7.jpg";
import Img8 from "./Img/UI-UXImg/Img8.jpg";

export default function UIUXDev(params) {
    return (
        <>

            <section>
                <div className="px-10 py-5 pt-16">
                    <h1 className="my-3 sm:my-6  text-gray-800 text-2xl  sm:text-5xl">UI-UX Development - Tech Saksham</h1>
                    <h1 className="my-3 sm:my-6  text-gray-500 text-sm  sm:text-xl">"Transform user interactions with our UI/UX development services. We design intuitive, visually engaging interfaces that enhance user experience and drive satisfaction, making your digital products stand out."</h1>
                    <button className=" my-4 mr-6 inline-flex h-10 sm:h-12 items-center justify-center rounded bg-cyan-500 px-6 font-medium tracking-wide text-white shadow-md outline-none transition duration-200 hover:bg-cyan-400 focus:ring ">Contact Us</button>
                </div>
            </section>
            <section>
                <div className="p-10 sm:py-20">
                <img src={Img1} className="w-full h-40 hidden sm:block sm:h-screen   rounded-3xl hover:shadow-2xl border bg-cover bg-center " alt="" />
                <img src={Img2} className="w-full h-full sm:hidden  rounded-3xl hover:shadow-2xl border bg-cover bg-center " alt="" />
                </div>
            </section>
            <section>
                <div>
                    <section class="bg-white py-6 sm:py-8 lg:py-12">
                        <div class="mx-auto max-w-screen-xl px-4 md:px-8">
                            <div class="mb-10 md:mb-16">
                                <h2 class="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 lg:text-3xl">Professional Services We Provide</h2>

                                <p class="mx-auto max-w-screen-md text-center text-gray-500 md:text-lg">High-Quality Services for Your Business Growth</p>
                            </div>
                            <div class="grid gap-8 grid-cols-2 sm:gap-12 lg:grid-cols-2 xl:grid-cols-2 xl:gap-16">
                                <article class="flex flex-col items-center gap-4 md:flex-row lg:gap-6">
                                    <div href="#" class="group relative block h-28 sm:h-56 w-full shrink-0 self-start overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-24 md:w-24 lg:h-40 lg:w-40">
                                        <img src={Img3} loading="lazy" alt="" class="absolute inset-0 sm:h-full sm:w-full object-cover object-center transition duration-200 group-hover:scale-110" />
                                    </div>

                                    <div class="flex flex-col gap-2">


                                        <h2 class="text-xl font-bold text-gray-800 transition duration-100 hover:text-cyan-500 active:text-cyan-600">
                                            <h1 >User Research & Analysis</h1>
                                        </h2>

                                        <p class="text-gray-500">Conducting thorough research to understand user needs, preferences, and behaviors, which informs design decisions.</p>


                                    </div>
                                </article>
                                <article class="flex flex-col items-center gap-4 md:flex-row lg:gap-6">
                                    <div class="group relative block h-28 sm:h-56 w-full shrink-0 self-start overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-24 md:w-24 lg:h-40 lg:w-40">
                                        <img src={Img4} loading="lazy" alt="" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
                                    </div>

                                    <div class="flex flex-col gap-2">


                                        <h2 class="text-xl font-bold text-gray-800 transition duration-100 hover:text-cyan-500 active:text-cyan-600">
                                            <h1 >Wireframing & Prototyping</h1>
                                        </h2>

                                        <p class="text-gray-500">Creating wireframes and interactive prototypes to demonstrate the structure and functionality of the design before the development phase.</p>


                                    </div>
                                </article>
                                <article class="flex flex-col items-center gap-4 md:flex-row lg:gap-6">
                                    <div class="group relative block  h-28 sm:h-56 w-full shrink-0 self-start overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-24 md:w-24 lg:h-40 lg:w-40">
                                        <img src={Img5} loading="lazy" alt="" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
                                    </div>

                                    <div class="flex flex-col gap-2">


                                        <h2 class="text-xl font-bold text-gray-800 transition duration-100 hover:text-cyan-500 active:text-cyan-600">
                                            <h1 >UI Design (User Interface)</h1>
                                        </h2>

                                        <p class="text-gray-500">Designing visually appealing and intuitive interfaces for websites and apps, ensuring that they align with the brand’s identity.</p>


                                    </div>
                                </article>
                                <article class="flex flex-col items-center gap-4 md:flex-row lg:gap-6">
                                    <div class="group relative block  h-28 sm:h-56 w-full shrink-0 self-start overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-24 md:w-24 lg:h-40 lg:w-40">
                                        <img src={Img6} loading="lazy" alt="" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
                                    </div>

                                    <div class="flex flex-col gap-2">


                                        <h2 class="text-xl font-bold text-gray-800 transition duration-100 hover:text-cyan-500 active:text-cyan-600">
                                            <h1 >UX Design (User Experience)</h1>
                                        </h2>

                                        <p class="text-gray-500">Enhancing user satisfaction by improving usability, accessibility, and interaction flows for digital products.</p>


                                    </div>
                                </article>
                                <article class="flex flex-col items-center gap-4 md:flex-row lg:gap-6">
                                    <div href="#" class="group relative block  h-28 sm:h-56 w-full shrink-0 self-start overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-24 md:w-24 lg:h-40 lg:w-40">
                                        <img src={Img7} loading="lazy" alt="" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
                                    </div>

                                    <div class="flex flex-col gap-2">


                                        <h2 class="text-xl font-bold text-gray-800 transition duration-100 hover:text-cyan-500 active:text-cyan-600">
                                            <h1 >Usability Testing</h1>
                                        </h2>

                                        <p class="text-gray-500">Conducting usability testing to identify and resolve issues in the user experience before launch.</p>


                                    </div>
                                </article>
                                <article class="flex flex-col items-center gap-4 md:flex-row lg:gap-6">
                                    <div href="#" class="group relative block  h-28 sm:h-56 w-full shrink-0 self-start overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-24 md:w-24 lg:h-40 lg:w-40">
                                        <img src={Img8} loading="lazy" alt="" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
                                    </div>

                                    <div class="flex flex-col gap-2">


                                        <h2 class="text-xl font-bold text-gray-800 transition duration-100 hover:text-cyan-500 active:text-cyan-600">
                                            <h1 >Responsive Design</h1>
                                        </h2>

                                        <p class="text-gray-500">Ensuring that your designs look great and function perfectly across all devices, including desktops, tablets, and smartphones.</p>


                                    </div>
                                </article>

                            </div>
                        </div>
                    </section>

                </div>
            </section>
            <div>
                <div className="flex justify-center items-center  px-4 sm:px-10">
                    <div className="  justify-center">
                        <h1 className=" font-semibold text-gray-800 text-3xl sm:text-4xl">UI-UX Development Portfolio :</h1>
                        <div className="flex items-center text-center justify-center">
                            <div >
                                <h1 className=" sm:px-16  text-gray-500 py-3 sm:py-20   text-justify    ">We’re currently updating this div of our website to improve your experience. We apologize for any inconvenience this may cause and UI-UXreciate your patience. Please check back later or contact our support team if you need immediate assistance.</h1>
                                <h1 className="py-4">       Thank you for your understanding!</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           

            <div className="py-10 px-10">
                <div className="flex justify-center items-center">
                    <div>

                        <div className="text-center">
                            <h1 className="py-5 text-3xl text-gray-800">Thank You for Visiting Our Website!</h1>
                            <h1 className="text-gray-500 text-justify">We UI-UXreciate you taking the time to explore our site. If you have any questions or need further information, <br className="hidden sm:block" /> please don't hesitate to contact us. We're here to help!</h1>
                        </div>
                        <div className="text-center">
                            <button className=" my-4 mr-6 inline-flex h-8 items-center justify-center rounded bg-cyan-500 px-6 font-medium tracking-wide text-white shadow-md outline-none transition duration-200 hover:bg-cyan-400 focus:ring ">Contact Us</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

