// Ram Ram
import Logo from "./logo.png";
import { useState } from "react";

import Angular from "../logo/Angular.png";
import C from "../logo/C.png";
import CC from "../logo/CC.png";
import JavaScript from "../logo/JavaScript.png";
import Kotlin from "../logo/Kotlin.png";
import MongoDB from "../logo/MongoDB.png";
import Mysql from "../logo/Mysql.png";
import Php from "../logo/Php.png";
import PostgreSQL from "../logo/PostgreSQL.png";
import Python from "../logo/Python.png";
import Reacta from "../logo/React.png";
import Typescript from "../logo/Typescript.png";
import Vuejs from "../logo/Vuejs.png";
import Node from "../logo/Nodejs.png";




export default function SoftwareDev(params) {
    return (
        <>

            <section>
                <div className="px-10 py-5 pt-16">
                    <h1 className=" my-6  text-gray-800  text-5xl">Software Development - Tech Saksham</h1>
                    <h1 className=" my-6  text-gray-500  text-xl">"Elevate your business with our top-notch software development services. We deliver customized, scalable solutions to streamline processes, boost efficiency, and drive innovation tailored to your unique needs."</h1>
                    <button className=" my-4 mr-6 inline-flex h-12 items-center justify-center rounded bg-cyan-500 px-6 font-medium tracking-wide text-white shadow-md outline-none transition duration-200 hover:bg-cyan-400 focus:ring ">Contact Us</button>
                </div>
            </section>
            <section>
                <div className="p-10 py-20">
                    <img src="https://images.pexels.com/photos/4549418/pexels-photo-4549418.jpeg?auto=compress&cs=tinysrgb&w=600" className="w-full h-screen   rounded-3xl hover:shadow-2xl border hover:border-gray-900 bg-cover bg-center " alt="" />
                </div>
            </section>
            <section>
                <div>
                    <section class="bg-white py-6 sm:py-8 lg:py-12">
                        <div class="mx-auto max-w-screen-xl px-4 md:px-8">
                            <div class="mb-10 md:mb-16">
                                <h2 class="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 lg:text-3xl">Professional Services We Provide</h2>

                                <p class="mx-auto max-w-screen-md text-center text-gray-500 md:text-lg">High-Quality Services for Your Business Growth</p>
                            </div>
                            <div class="grid gap-8 sm:grid-cols-2 sm:gap-12 lg:grid-cols-2 xl:grid-cols-2 xl:gap-16">
                                <article class="flex flex-col items-center gap-4 md:flex-row lg:gap-6">
                                    <h1 href="#" class="group relative block h-56 w-full shrink-0 self-start overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-24 md:w-24 lg:h-40 lg:w-40">
                                        <img src="https://images.unsplash.com/photo-1510081887155-56fe96846e71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=715&q=80" loading="lazy" alt="" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
                                    </h1>

                                    <div class="flex flex-col gap-2">


                                        <h2 class="text-xl font-bold text-gray-800 transition duration-100 hover:text-cyan-500 active:text-cyan-600">
                                            <h1 >Custom Solutions</h1>
                                        </h2>

                                        <p class="text-gray-500">We create bespoke Softwarelications tailored to meet your specific business needs and goals.</p>


                                    </div>
                                </article>
                                <article class="flex flex-col items-center gap-4 md:flex-row lg:gap-6">
                                    <h1 class="group relative block h-56 w-full shrink-0 self-start overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-24 md:w-24 lg:h-40 lg:w-40">
                                        <img src="https://images.unsplash.com/photo-1476362555312-ab9e108a0b7e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" loading="lazy" alt="" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
                                    </h1>

                                    <div class="flex flex-col gap-2">


                                        <h2 class="text-xl font-bold text-gray-800 transition duration-100 hover:text-cyan-500 active:text-cyan-600">
                                            <h1 >Innovative Designs</h1>
                                        </h2>

                                        <p class="text-gray-500">Our team focuses on turning your ideas into creative and functional mobile Software designs.</p>


                                    </div>
                                </article>
                                <article class="flex flex-col items-center gap-4 md:flex-row lg:gap-6">
                                    <h1 class="group relative block h-56 w-full shrink-0 self-start overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-24 md:w-24 lg:h-40 lg:w-40">
                                        <img src="https://images.unsplash.com/photo-1511376777868-611b54f68947?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" loading="lazy" alt="" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
                                    </h1>

                                    <div class="flex flex-col gap-2">


                                        <h2 class="text-xl font-bold text-gray-800 transition duration-100 hover:text-cyan-500 active:text-cyan-600">
                                            <h1 >User-Friendly Experience</h1>
                                        </h2>

                                        <p class="text-gray-500">We prioritize user experience to ensure that your Software is intuitive and easy to navigate.</p>


                                    </div>
                                </article>
                                <article class="flex flex-col items-center gap-4 md:flex-row lg:gap-6">
                                    <h1 class="group relative block h-56 w-full shrink-0 self-start overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-24 md:w-24 lg:h-40 lg:w-40">
                                        <img src="https://images.unsplash.com/photo-1496395031280-4201b0e022ca?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80" loading="lazy" alt="" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
                                    </h1>

                                    <div class="flex flex-col gap-2">


                                        <h2 class="text-xl font-bold text-gray-800 transition duration-100 hover:text-cyan-500 active:text-cyan-600">
                                            <h1 >Maximized ROI</h1>
                                        </h2>

                                        <p class="text-gray-500"> Our Softwares are designed to enhance your business efficiency and profitability.</p>


                                    </div>
                                </article>
                                <article class="flex flex-col items-center gap-4 md:flex-row lg:gap-6">
                                    <h1 href="#" class="group relative block h-56 w-full shrink-0 self-start overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-24 md:w-24 lg:h-40 lg:w-40">
                                        <img src="https://images.unsplash.com/photo-1510081887155-56fe96846e71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=715&q=80" loading="lazy" alt="" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
                                    </h1>

                                    <div class="flex flex-col gap-2">


                                        <h2 class="text-xl font-bold text-gray-800 transition duration-100 hover:text-cyan-500 active:text-cyan-600">
                                            <h1 >Scalability</h1>
                                        </h2>

                                        <p class="text-gray-500">We build scalable Softwarelications that can grow with your business and adapt to evolving needs.</p>


                                    </div>
                                </article>
                                <article class="flex flex-col items-center gap-4 md:flex-row lg:gap-6">
                                    <h1 href="#" class="group relative block h-56 w-full shrink-0 self-start overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-24 md:w-24 lg:h-40 lg:w-40">
                                        <img src="https://images.unsplash.com/photo-1510081887155-56fe96846e71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=715&q=80" loading="lazy" alt="" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
                                    </h1>

                                    <div class="flex flex-col gap-2">


                                        <h2 class="text-xl font-bold text-gray-800 transition duration-100 hover:text-cyan-500 active:text-cyan-600">
                                            <h1 >End-to-End Support</h1>
                                        </h2>

                                        <p class="text-gray-500">From initial design to final deployment, we provide comprehensive support throughout the Software development lifecycle.</p>


                                    </div>
                                </article>

                            </div>
                        </div>
                    </section>

                </div>
            </section>
            <div>
                <div className="flex justify-center items-center  px-10">
                    <div className="  justify-center">
                        <h1 className=" font-semibold text-gray-800 text-4xl">Software Development Portfolio :</h1>
                        <div className="flex items-center text-center justify-center">
                            <div >
                                <h1 className=" px-16  text-gray-500 py-20       ">We’re currently updating this div of our website to improve your experience. We apologize for any inconvenience this may cause and Softwarereciate your patience. Please check back later or contact our support team if you need immediate assistance.</h1>
                                <h1 className="py-4">       Thank you for your understanding!</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Section 6 Start */}
            <div className="block sm:hidden">
                <Tabs />
            </div>
            {/* Section 6 End */}
            {/* Section 7 Start */}
            <div className="hidden sm:block">
                <Tabscom />
            </div>
            {/* Section 7 End */}

            <div className="py-10 px-10">
                <div className="flex justify-center items-center">
                    <div>

                        <div className="text-center">
                            <h1 className="py-5 text-3xl text-gray-800">Thank You for Visiting Our Website!</h1>
                            <h1 className="text-gray-500">We Softwarereciate you taking the time to explore our site. If you have any questions or need further information, <br /> please don't hesitate to contact us. We're here to help!</h1>
                        </div>
                        <div className="text-center">
                            <button className=" my-4 mr-6 inline-flex h-8 items-center justify-center rounded bg-cyan-500 px-6 font-medium tracking-wide text-white shadow-md outline-none transition duration-200 hover:bg-cyan-400 focus:ring ">Contact Us</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}


const Tabs = () => {
    // State to track the active tab
    const [activeTab, setActiveTab] = useState('tab1');

    // Function to switch tabs
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="tabs-container">
            {/* Tab headers */}
            <div className="border-b border-gray-200">
                <nav className="grid grid-cols-2 space-x-4">
                    <button
                        className={`py-2 px-4 font-medium ${activeTab === 'tab1'
                            ? 'border-b-2 border-indigo-600 text-indigo-600'
                            : 'text-gray-500 hover:text-gray-700'
                            }`}
                        onClick={() => handleTabClick('tab1')}
                    >
                        Development
                    </button>
                    <button
                        className={`py-2 px-4 font-medium ${activeTab === 'tab2'
                            ? 'border-b-2 border-indigo-600 text-indigo-600'
                            : 'text-gray-500 hover:text-gray-700'
                            }`}
                        onClick={() => handleTabClick('tab2')}
                    >
                        DataBase
                    </button>
                    <button
                        className={`py-2 px-4 font-medium ${activeTab === 'tab3'
                            ? 'border-b-2 border-indigo-600 text-indigo-600'
                            : 'text-gray-500 hover:text-gray-700'
                            }`}
                        onClick={() => handleTabClick('tab3')}
                    >
                        Frontend
                    </button>
                    <button
                        className={`py-2 px-4 font-medium ${activeTab === 'tab4'
                            ? 'border-b-2 border-indigo-600 text-indigo-600'
                            : 'text-gray-500 hover:text-gray-700'
                            }`}
                        onClick={() => handleTabClick('tab4')}
                    >
                        Backend
                    </button>
                </nav>
            </div>

            {/* Tab content */}
            <div className="p-4">
                {activeTab === 'tab1' && (
                    <div>
                        <div>
                            <h1 className="text-xl font-semibold"> Development :</h1>
                            <h1 className="text-xs mt-4">"Transform your online presence with our expert web development services. We create modern, user-friendly websites that engage your audience and drive results, tailored to your business goals."</h1>
                        </div>
                        <div className="grid grid-cols-2 gap-5 pt-5">
                            <div className="flex justify-center items-center">
                                <img src={Angular} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                            </div>
                            <div className="flex justify-center items-center">
                                <img src={JavaScript} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                            </div>
                            <div className="flex justify-center items-center">
                                <img src={Kotlin} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                            </div>
                            <div className="flex justify-center items-center">
                                <img src={Python} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                            </div>
                            <div className="flex justify-center items-center">
                                <img src={C} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                            </div>
                            <div className="flex justify-center items-center">
                                <img src={CC} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                            </div>
                            <div className="flex justify-center items-center">
                                <img src={Typescript} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                            </div>
                            <div className="flex justify-center items-center">
                                <img src={Php} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                            </div>
                        </div>
                    </div>
                )}
                {activeTab === 'tab2' && (
                    <div>
                    <div>
                        <h1 className="text-xl font-semibold">DataBase :</h1>
                        <h1 className="text-xs mt-4">"Optimize your data management with our reliable database solutions. We design scalable, secure databases that ensure efficient data storage, access, and analysis to support your business growth."</h1>
                    </div>
                    <div className="grid grid-cols-2 gap-5 pt-5">
                        <div className="flex justify-center items-center">
                            <img src={Mysql} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                        </div>
                        <div className="flex justify-center items-center">
                            <img src={MongoDB} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                        </div>
                        <div className="flex justify-center items-center">
                            <img src={PostgreSQL} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                        </div>
                        <div className="flex justify-center items-center">
                            <img src={Php} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                        </div>


                    </div>
                </div>
                )}
                {activeTab === 'tab3' && (
                 <div>
                 <div>
                     <h1 className="text-xl font-semibold">Frontend :</h1>
                     <h1 className="text-xs mt-4">"Enhance your website with stunning, responsive front-end development. We craft visually appealing, interactive user interfaces that deliver seamless experiences across all devices and platforms."</h1>
                 </div>
                 <div className="grid grid-cols-2 gap-5 pt-5">
                     <div className="flex justify-center items-center">
                         <img src={Angular} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                     </div>
                     <div className="flex justify-center items-center">
                         <img src={JavaScript} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                     </div>
                     <div className="flex justify-center items-center">
                         <img src={Typescript} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                     </div>
                     <div className="flex justify-center items-center">
                         <img src={Python} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                     </div>
                     <div className="flex justify-center items-center">
                         <img src={Reacta} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                     </div>
                     <div className="flex justify-center items-center">
                         <img src={Vuejs} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                     </div>
                 </div>
             </div>
                )}
                {activeTab === 'tab4' && (
                   <div>
                   <div>
                       <h1 className="text-xl font-semibold">Backend :</h1>
                       <h1 className="text-xs mt-4">"Streamline your operations with our expert backend development. We create reliable, scalable server-side solutions to handle complex data processing and ensure smooth, efficient application performance."</h1>
                   </div>
                   <div className="grid grid-cols-2 gap-5 pt-5">
                       <div className="flex justify-center items-center">
                           <img src={Angular} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                       </div>
                       <div className="flex justify-center items-center">
                           <img src={JavaScript} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                       </div>
                       <div className="flex justify-center items-center">
                           <img src={Python} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                       </div>
                       <div className="flex justify-center items-center">
                           <img src={Node} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                       </div>
                       <div className="flex justify-center items-center">
                           <img src={C} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                       </div>
                       <div className="flex justify-center items-center">
                           <img src={CC} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                       </div>
                   </div>
               </div>
                )}
            </div>
        </div>
    );
};

const Tabscom = () => {
    // State to track the active tab
    const [activeTabcom, setActiveTabcom] = useState('tab1');

    // Function to switch tabs
    const handleTabClickcom = (tab) => {
        setActiveTabcom(tab);
    };

    return (
        <>
            <hr className="mt-5" />
            <div className="grid grid-cols-8">
                <div className="col-start-1 col-end-3 flex justify-center items-center">
                    <img src={Logo} className="w-32 h-32" alt="" />
                </div>
                <div className="col-start-3 col-end-9 p-4 flex justify-center items-center">
                    <div className="">
                        <h1 className="text-2xl font-semibold">"Building Better with a Tech Stack That Stands Out"</h1>
                        <h1 className="ms-16 mt-5">         "We utilize the most effective technologies for your product, whether they are the latest trends
                            or proven classics."</h1>
                    </div>
                </div>
            </div>
            <div className="tabs-container grid grid-cols-8">
                {/* Tab headers */}
                <div className="flex items-center justify-center col-start-1 col-end-3 border-b border-gray-200">
                    <div>
                        <div className="text-center">
                            <h1 className="text-xl font-semibold">Technology We Use</h1>
                        </div>
                        <nav className="grid grid-cols-1 space-x-4">
                            <button
                                className={`py-2 px-4 font-medium ${activeTabcom === 'tab1'
                                    ? 'border-b-2 border-indigo-600 text-indigo-600'
                                    : 'text-gray-500 hover:text-gray-700'
                                    }`}
                                onClick={() => handleTabClickcom('tab1')}
                            >
                                Development
                            </button>
                            <button
                                className={`py-2 px-4 font-medium ${activeTabcom === 'tab2'
                                    ? 'border-b-2 border-indigo-600 text-indigo-600'
                                    : 'text-gray-500 hover:text-gray-700'
                                    }`}
                                onClick={() => handleTabClickcom('tab2')}
                            >
                                DataBase
                            </button>
                            <button
                                className={`py-2 px-4 font-medium ${activeTabcom === 'tab3'
                                    ? 'border-b-2 border-indigo-600 text-indigo-600'
                                    : 'text-gray-500 hover:text-gray-700'
                                    }`}
                                onClick={() => handleTabClickcom('tab3')}
                            >
                                Frontend
                            </button>
                            <button
                                className={`py-2 px-4 font-medium ${activeTabcom === 'tab4'
                                    ? 'border-b-2 border-indigo-600 text-indigo-600'
                                    : 'text-gray-500 hover:text-gray-700'
                                    }`}
                                onClick={() => handleTabClickcom('tab4')}
                            >
                                Backend
                            </button>
                        </nav>
                    </div>
                </div>

                {/* Tab content */}
                <div className="col-start-3 col-end-9 p-4">
                    {activeTabcom === 'tab1' && (
                        <div>
                            <div>
                                <h1 className="text-xl font-semibold"> Development :</h1>
                                <h1 className="text-xs mt-4">"Transform your online presence with our expert web development services. We create modern, user-friendly websites that engage your audience and drive results, tailored to your business goals."</h1>
                            </div>
                            <div className="grid grid-cols-4 gap-5 pt-5">
                                <div className="flex justify-center items-center">
                                    <img src={Angular} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src={JavaScript} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src={Kotlin} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src={Python} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src={C} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src={CC} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src={Typescript} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src={Php} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                                </div>
                            </div>
                        </div>
                    )}
                    {activeTabcom === 'tab2' && (
                        <div>
                            <div>
                                <h1 className="text-xl font-semibold">DataBase :</h1>
                                <h1 className="text-xs mt-4">"Optimize your data management with our reliable database solutions. We design scalable, secure databases that ensure efficient data storage, access, and analysis to support your business growth."</h1>
                            </div>
                            <div className="grid grid-cols-4 gap-5 pt-5">
                                <div className="flex justify-center items-center">
                                    <img src={Mysql} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src={MongoDB} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src={PostgreSQL} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src={Php} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                                </div>


                            </div>
                        </div>
                    )}
                    {activeTabcom === 'tab3' && (
                        <div>
                            <div>
                                <h1 className="text-xl font-semibold">Frontend :</h1>
                                <h1 className="text-xs mt-4">"Enhance your website with stunning, responsive front-end development. We craft visually appealing, interactive user interfaces that deliver seamless experiences across all devices and platforms."</h1>
                            </div>
                            <div className="grid grid-cols-4 gap-5 pt-5">
                                <div className="flex justify-center items-center">
                                    <img src={Angular} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src={JavaScript} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src={Typescript} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src={Python} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src={Reacta} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src={Vuejs} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                                </div>
                            </div>
                        </div>
                    )}
                    {activeTabcom === 'tab4' && (
                        <div>
                            <div>
                                <h1 className="text-xl font-semibold">Backend :</h1>
                                <h1 className="text-xs mt-4">"Streamline your operations with our expert backend development. We create reliable, scalable server-side solutions to handle complex data processing and ensure smooth, efficient application performance."</h1>
                            </div>
                            <div className="grid grid-cols-4 gap-5 pt-5">
                                <div className="flex justify-center items-center">
                                    <img src={Angular} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src={JavaScript} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src={Python} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src={Node} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src={C} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src={CC} className="w-10 overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
