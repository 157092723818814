// Radhe Radhe Ram Ram
import logo from './logo.svg';
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import Home from './Components/Home/Home';
import Footer from './Components/Footer/Footer';
import AppDev from './Components/Service/Appdev';
import WebDev from './Components/Service/Webdev';
import SoftwareDev from './Components/Service/Software';
import UIUXDev from './Components/Service/UIUXdev';
import ContactUs from './Components/Contact/Contact';
import Site_demo_TheHotel_Home from './Components/SiteData/TheFoodHotel/Pages/Home';


function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/Service/Web-development' element={<WebDev />} />
        <Route path='/Service/Ui-UX-design' element={<UIUXDev />} />
        <Route path='/Service/App-development' element={<AppDev />} />
        <Route path='/Service/Software-development' element={<SoftwareDev />} />
        <Route path='/Contact-Us' element={<ContactUs />} />
      </Routes>
      <Footer />


   



    </>
  );
}

export default App;
