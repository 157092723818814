import React, { useState } from "react";


import Logo from "./a1.jpg";
export default function ContactUs(params) {
   
    
    return (
        <>



            <section>
                <div className="px-10 py-5 pt-16">
                    <h1 className=" my-6  text-gray-800  text-5xl">Contact Us</h1>
                    <h1 className=" my-6  text-gray-500  text-xl">Let Us Know How We Can Help You</h1>
                </div>
            </section>
            <section class="py-24">
                <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div class="grid lg:grid-cols-2 grid-cols-1">
                        <div class="lg:mb-0 mb-10">
                            <div class="group w-full h-full">
                                <div class="relative h-full">
                                    <img src={Logo} alt="ContactUs tailwind section" class="w-full h-full lg:rounded-l-2xl rounded-2xl bg-blend-multiply bg-cyan-700 object-cover" />
                                    <h1 class="font-manrope text-white text-4xl font-bold leading-10 absolute top-11 left-11">Contact us</h1>
                                    <div class="absolute bottom-0 w-full lg:p-11 p-5">
                                        <div class="bg-white rounded-lg p-6 block">
                                            <a href="javascript:;" class="flex items-center mb-6">

                                                <h5 class="text-black text-base font-normal leading-6 ml-5">+91 98501 21183</h5>
                                            </a>
                                            <a href="javascript:;" class="flex items-center mb-6">

                                                <h5 class="text-black text-base font-normal leading-6 ml-5">sakshambangale779@gmail.com</h5>
                                            </a>
                                            <a href="javascript:;" class="flex items-center">

                                                <h5 class="text-black text-base font-normal leading-6 ml-5">Ramnagar, Wardha, Maharashtra 442001</h5>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="bg-gray-50 p-5 lg:p-11 lg:rounded-r-2xl rounded-2xl">
                            <h2 class="text-cyan-600 font-manrope text-4xl font-semibold leading-10 mb-11">Send Us A Message</h2>
                            <input type="text" class="w-full h-12 text-gray-600 placeholder-gray-400  shadow-sm bg-transparent text-lg font-normal leading-7 rounded-full border border-gray-200 focus:outline-none pl-4 mb-10"
                              placeholder="Name" />
                            <input type="text" class="w-full h-12 text-gray-600 placeholder-gray-400 shadow-sm bg-transparent text-lg font-normal leading-7 rounded-full border border-gray-200 focus:outline-none pl-4 mb-10"
                              placeholder="Email" />
                            <input type="text" class="w-full h-12 text-gray-600 placeholder-gray-400 shadow-sm bg-transparent text-lg font-normal leading-7 rounded-full border border-gray-200 focus:outline-none pl-4 mb-10"
                               placeholder="Phone" />
                            <input type="text" class="w-full h-12 text-gray-600 placeholder-gray-400 bg-transparent text-lg shadow-sm font-normal leading-7 rounded-full border border-gray-200 focus:outline-none pl-4 mb-10"
                               placeholder="Message" />
                            <button   class="w-full h-12 text-white text-base font-semibold leading-6 rounded-full transition-all duration-700 hover:bg-cyan-800 bg-cyan-600 shadow-sm">Send</button>
                        </div>
                    </div>
                </div>

            </section >
            <section class="w-screen bg-white py-10 text-gray-800">
                <div class="container mx-auto w-full max-w-screen-xl">

                    <div class="flex flex-col lg:flex-row">
                        <div class="w-full p-4 text-left lg:w-1/3">
                            <hr class="mb-4 h-1.5 w-1/4 bg-cyan-600" />
                            <h3 class="font-sans text-4xl font-light leading-10">Contact Us :</h3>
                            <p class="my-5 text-gray-600"> +91 98501 21183</p>
                        </div>
                        <div class="w-full p-4 text-left lg:w-1/3">
                            <hr class="mb-4 h-1.5 w-1/4 bg-cyan-600" />
                            <h3 class="font-sans text-4xl font-light leading-10">Address :</h3>
                            <p class="my-5 text-gray-600">Ramnagar, Wardha, Maharashtra 442001</p>
                        </div>
                        <div class="w-full p-4 text-left lg:w-1/3">
                            <hr class="mb-4 h-1.5 w-1/4 bg-cyan-600" />
                            <h3 class="font-sans text-4xl font-light leading-10">Email :</h3>
                            <p class="my-5 text-gray-600">sakshambangale779@gmail.com</p>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}