import React, { useState } from "react";
import Logo from './Img/logo.png'
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
export default function Navbar(params) {
    const [mobtoggle, setmobtoggle] = useState(false)
    const [comtoggle_Services, setcomtoggle_Services] = useState(false)
    const [comtoggle_Industries, setcomtoggle_Industries] = useState(false)

    const [mobtoggle_Services, setmobtoggle_Services] = useState(false)
    const [mobtoggle_Industries, setmobtoggle_Industries] = useState(false)
    const [opent, setopent] = useState(false)


    function mobOpenMenuBar(params) {
        setmobtoggle(true)
    }
    function mobhideSidemenu(params) {
        setmobtoggle(false)
    }

    function comOpenMenuBar_Services(params) {
        setcomtoggle_Services(true)
    }
    function comhideSidemenu_Services(params) {
        setcomtoggle_Services(false)
    }

    function comOpenMenuBar_Industries(params) {
        setcomtoggle_Industries(true)
    }
    function comhideSidemenu_Industries(params) {
        setcomtoggle_Industries(false)
    }

    function mob_Service_open(params) {
        setmobtoggle_Services(mobtoggle_Services => !mobtoggle_Services)
        setmobtoggle_Industries(false)
    }
    function mob_Industries_open(params) {
        setmobtoggle_Industries(mobtoggle_Industries => !mobtoggle_Industries)
        setmobtoggle_Services(false)
    }
    return (
        <>
            {/* computer screen Servic */}
            <div className='hidden z-50  sm:block w-full h-full black-overlay fixed items-center'
                style={{
                    opacity: comtoggle_Services ? 1 : 0,
                    visibility: comtoggle_Services ? "visible" : 'hidden',
                    backgroundColor: "rgba(0, 0, 0, 0.7)"
                }}
                onClick={comhideSidemenu_Services}
            >
                <div onClick={(e) => { e.stopPropagation() }} className='bg-white   absolute duration-1000 w-screen h-screen'
                    style={{ top: comtoggle_Services ? "0%" : "-100%" }}
                >
                    <div className="text-4xl flex justify-between">
                        <div className="ms-8 justify-center text-center">
                            <h1>Tech Saksham</h1>
                        </div>

                        <div className="text-right">
                            <button onClick={comhideSidemenu_Services} className=" p-2 rounded-full text-4xl"><i class="fa-solid fa-xmark"></i></button>
                        </div>
                    </div>
                    <div className=' '>
                        <div className="grid grid-cols-4">
                            <div>

                                <div className="">

                                </div>
                                <div className="flex items-center justify-center">
                                    <img src={Logo} className="w-32" alt="" />
                                </div>
                                <div className="text-center">
                                    <h1 className="text-2xl hover:border-b-2  hover:border-b-violet-600">Service</h1>
                                </div>

                            </div>
                            <div className="grid grid-cols-3 col-start-2 col-end-5 p-4 border border-gray-300">

                                <Link to="/Service/App-development" onClick={comhideSidemenu_Services}>
                                    <div className="m-2">
                                        <div class="hover:shadow-2xl max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">

                                            <a href="#">
                                                <h5 class="mb-2 text-xl font-semibold tracking-tight text-gray-900 dark:text-white">App Development</h5>
                                            </a>
                                            <p class="mb-3 text-sm text-gray-500 dark:text-gray-400">"Building custom apps tailored to your needs, offering seamless functionality and innovative user experiences."</p>
                                            <a href="#" class="inline-flex font-medium items-center text-blue-600 hover:underline">
                                                See More ....
                                            </a>
                                        </div>
                                    </div>
                                </Link>
                                <Link to="/Service/Web-development" onClick={comhideSidemenu_Services}>
                                    <div className="m-2">
                                        <div class="hover:shadow-2xl max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">

                                            <a href="#">
                                                <h5 class="mb-2 text-xl font-semibold tracking-tight text-gray-900 dark:text-white">Web Development</h5>
                                            </a>
                                            <p class="mb-3 text-sm text-gray-500 dark:text-gray-400">"Crafting responsive, modern websites that engage users and drive growth with intuitive design and functionality."</p>
                                            <a href="#" class="inline-flex font-medium items-center text-blue-600 hover:underline">
                                                See More ....

                                            </a>
                                        </div>
                                    </div>
                                </Link>



                                <Link to="/Service/Software-development" onClick={comhideSidemenu_Services}>
                                    <div className="m-2">
                                        <div class="hover:shadow-2xl max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">

                                            <a href="#">
                                                <h5 class="mb-2 text-xl font-semibold tracking-tight text-gray-900 dark:text-white">Software Development</h5>
                                            </a>
                                            <p class="mb-3 text-sm text-gray-500 dark:text-gray-400">"Developing scalable, efficient software solutions that streamline operations and enhance business performance."</p>
                                            <a href="#" class="inline-flex font-medium items-center text-blue-600 hover:underline">
                                                See More ....

                                            </a>
                                        </div>
                                    </div>
                                </Link>

                                <Link to="/Service/App-development" onClick={comhideSidemenu_Services}>
                                    <div className="m-2">
                                        <div class="hover:shadow-2xl max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">

                                            <a href="#">
                                                <h5 class="mb-2 text-xl font-semibold tracking-tight text-gray-900 dark:text-white">Digital Marketing</h5>
                                            </a>
                                            <p class="mb-3 text-sm text-gray-500 dark:text-gray-400">"Boosting your online presence with targeted digital marketing strategies to increase visibility, engagement, and conversions."</p>
                                            <a href="#" class="inline-flex font-medium items-center text-blue-600 hover:underline">
                                                See More ....

                                            </a>
                                        </div>
                                    </div>

                                </Link>
                                <Link to="/Service/Ui-UX-design" onClick={comhideSidemenu_Services}>
                                    <div className="m-2">
                                        <div class="hover:shadow-2xl max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">

                                            <a href="#">
                                                <h5 class="mb-2 text-xl font-semibold tracking-tight text-gray-900 dark:text-white">UI-UX Design</h5>
                                            </a>
                                            <p class="mb-3 text-sm text-gray-500 dark:text-gray-400">"Designing intuitive and visually engaging user interfaces that enhance user experience and drive satisfaction."</p>
                                            <a href="#" class="inline-flex font-medium items-center text-blue-600 hover:underline">
                                                See More ....

                                            </a>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div >



            {/* computer screen Industries */}
            < div className='hidden z-50  sm:block w-full h-full black-overlay fixed items-center'
                style={{
                    opacity: comtoggle_Industries ? 1 : 0,
                    visibility: comtoggle_Industries ? "visible" : 'hidden',
                    backgroundColor: "rgba(0, 0, 0, 0.7)"
                }
                }
                onClick={comhideSidemenu_Industries}
            >
                <div onClick={(e) => { e.stopPropagation() }} className='bg-white   absolute duration-1000 w-screen h-screen'
                    style={{ top: comtoggle_Industries ? "0%" : "-100%" }}
                >
                    <div className="text-4xl flex justify-between">
                        <div className="ms-8 justify-center text-center">
                            <Link to="/">
                                <h1>Tech Saksham</h1>
                            </Link>
                        </div>

                        <div className="text-right">
                            <button onClick={comhideSidemenu_Industries} className=" p-2 rounded-full text-4xl"><i class="fa-solid fa-xmark"></i></button>
                        </div>
                    </div>
                    <div className=' '>
                        <div className="grid grid-cols-4">
                            <div>

                                <div className="">

                                </div>
                                <div className="flex items-center justify-center">
                                    <img src={Logo} className="w-32" alt="" />
                                </div>
                                <div className="text-center">
                                    <h1 className="text-2xl hover:border-b-2  hover:border-b-violet-600">Industries</h1>
                                </div>

                            </div>
                            <div className="grid grid-cols-1 justify-center items-center col-start-2 text-center col-end-5 p-4 border border-gray-300">


                                <h1>
                                    "We're sorry for the disruption. This section of our website is being updated to enhance your experience. We appreciate your patience and look forward to bringing you a better service soon."
                                </h1>



                            </div>
                        </div>
                    </div>
                </div>
            </div >

            {/* computer screen Industries */}
            < div className=' z-50  sm:block w-full h-full black-overlay fixed items-center'
                style={{
                    opacity: mobtoggle ? 1 : 0,
                    visibility: mobtoggle ? "visible" : 'hidden',
                    backgroundColor: "rgba(0, 0, 0, 0.7)"
                }}
                onClick={mobhideSidemenu}
            >
                <div onClick={(e) => { e.stopPropagation() }} className={(mobtoggle_Services?'bg-white   absolute duration-1000 w-screen h-[75%]':'bg-white   absolute duration-1000 w-screen h-[50%]')}
                    style={{ top: mobtoggle ? "0%" : "-100%" }}
                >
                    <div className="text-4xl flex justify-between items-center">
                        <div className="ms-2 justify-center text-center">
                            <h1 className="text-2xl">Tech Saksham</h1>
                        </div>

                        <div className="text-right">
                            <button onClick={mobhideSidemenu} className=" m-4 rounded-full text-xl"><i class="fa-solid fa-xmark"></i></button>
                        </div>
                    </div>
                    <div className=' '>
                        <div>
                            <div className="w-full   " >
                                <div className="" >
                                    <div>
                                        <Link to="/" onClick={mobhideSidemenu}>
                                            <h1 className="text-xl px-5 py-4">Home</h1>
                                        </Link>
                                    </div>
                                    <div className=" flex items-center justify-between " onClick={mob_Service_open}>
                                        <div className="group">
                                            <h1 className="text-xl  px-5 py-4 " >Services</h1>
                                        </div>
                                        <div>
                                            {
                                                mobtoggle_Services ? <i className="pe-10 fa-xl  fa-solid fa-caret-down"></i> : <i className="pe-10 fa-xl  fa-solid fa-caret-right"></i>
                                            }

                                        </div>
                                    </div>
                                    <div className="">
                                        {
                                            mobtoggle_Services ? <><div>
                                                <ul>

                                                    <Link to="/Service/Ui-UX-design" onClick={mobhideSidemenu}>
                                                        <li className="py-2 px-16">Ui-Ux Design </li>
                                                    </Link>
                                                    <Link to="/Service/Web-development" onClick={mobhideSidemenu}>
                                                        <li className="py-2 px-16">Web Development</li>
                                                    </Link>
                                                    <Link to="/Service/App-development" onClick={mobhideSidemenu}>
                                                        <li className="py-2 px-16">App Development</li>
                                                    </Link>
                                                    <Link to="/Service/Software-development" onClick={mobhideSidemenu}>
                                                        <li className="py-2 px-16">Software Development</li>
                                                    </Link>
                                                </ul>
                                            </div></>
                                                : <></>
                                        }
                                    </div>
                                    <div className="flex items-center justify-between " onClick={mob_Industries_open}>
                                        <div>
                                            <h1 className="text-xl px-5 py-4" >Industries</h1>
                                        </div>
                                        <div>
                                            {
                                                mobtoggle_Industries ? <i className="pe-10 fa-xl  fa-solid fa-caret-down"></i> : <i className="pe-10 fa-xl  fa-solid fa-caret-right"></i>
                                            }

                                        </div>
                                    </div>
                                    <div className="">
                                        {
                                            mobtoggle_Industries ? <><div>
                                                <h1 className="px-16">"Please note, this section of the website is currently in development and will be available soon."</h1>
                                            </div></>
                                                : <></>
                                        }
                                    </div>
                                    <div>
                                        <h1 className="text-xl px-5 py-4">Portfolio</h1>
                                    </div>
                                    <div>
                                        <Link to="/Contact-Us">
                                            <h1 className="text-xl px-5 py-4">Contact Us</h1>
                                        </Link>
                                    </div>

                                </div>


                            </div>



                        </div>
                    </div>
                </div>
            </div >


            <div className=" ps-3 pe-3 pt-4 pb-4 sm:pt-1 sm:pb-2 sm:ps-2 sm:pe-2 top-0 sticky z-40 bg-white shadow-2xlx">
                <div className="flex justify-between items-center">
                    <div className="hidden sm:block">
                        <Link to="/">
                        </Link>
                        <img className="w-10" src={Logo} alt="" />
                    </div>
                    <div className=" sm:block">
                        <Link to="/">
                            <span className="text-2xl font-bold font-serif">Tech Saksham</span>
                        </Link>
                    </div>
                    <div className="hidden   sm:block">
                        <ul className="flex ">
                            <Link to="/">
                                <li className="m-2 ms-4 me-4 hover:border-b-2 hover:border-b-violet-600 group relative flex w-fit items-center" >Home  </li>
                            </Link>

                            <li className="m-2 ms-4 me-4 hover:border-b-2 hover:border-b-violet-600" onClick={comOpenMenuBar_Services} >Services</li>
                            <li className="m-2 ms-4 me-4 hover:border-b-2 hover:border-b-violet-600" onClick={comOpenMenuBar_Industries}>Industries</li>
                            <li className="m-2 ms-4 me-4 hover:border-b-2 hover:border-b-violet-600" >Portfolio </li>

                        </ul>
                    </div>
                    <div className="hidden sm:block">
                    <Link to="/Contact-Us">
                    <button className="border group flex cursor-pointer items-center  hover:bg-cyan-600 bg-none px-8 py-2 text-center font-semibold leading-tight hover:text-white border-gray-300 hover:border-violet-900 ps-5 pe-5 rounded-lg">Contact Us</button>
                    </Link> 
                    </div>
                    <div className="sm:hidden">
                        <button className="text-xl hover: ps-5 pe-5 rounded-lg" onClick={mobOpenMenuBar}><i class="fa-solid fa-bars"></i></button>
                    </div>
                </div>
            </div>





        </>
    )
}

